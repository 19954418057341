import * as Sentry from "@sentry/gatsby";

const sampleRate = process.env.SENTRY_SAMPLE_RATE ? process.env.SENTRY_SAMPLE_RATE as unknown as number : 0.1

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  debug: process.env.SENTRY_DEBUG === "true" ? true : false,
  environment: process.env.SENTRY_ENVIRONMENT,
  tracesSampleRate: sampleRate, // Adjust this value in production
});